import { ToastOptions } from 'react-toastify'
import { AxiosError, AxiosResponse } from 'axios'
import { HttpErrorMessage } from 'src/enums/error'
import { toasty } from 'src/components/toast/toasty'
import { ToastSubtitle } from 'src/components/toast/ToastSubtitle'

export type THttpError = {
  status: number | undefined
  message: HttpErrorMessage
  xTraceId?: string
}

const HttpErrorMessageTranslation = {
  [HttpErrorMessage.AlreadyMemberOfGroup]: 'Dieser Spieler ist bereits in einem Team.',
  [HttpErrorMessage.CurrentUserMemberOfGroup]:
    'Du kannst diesem Team nicht beitreten, da du bereits in einem anderen Team bist.',
  [HttpErrorMessage.EventHasResult]: 'Es gibt bereits ein Resultat',
  [HttpErrorMessage.RaceHasResults]: 'Dieses Rennen hat bereits ein Resultat',
  [HttpErrorMessage.RaceBetsOrResultsExist]: 'Dieses Rennen hat bereits Tipps oder ein Resultat',
  [HttpErrorMessage.BettingClosed]: 'Du kannst keine Tipps mehr abgeben',
  [HttpErrorMessage.IdInvalid]: 'ID ist ungültig',
  [HttpErrorMessage.InvalidProduct]: 'Produkt ist ungültig',
  [HttpErrorMessage.InvalidTeam]: 'Team ist ungültig',
  [HttpErrorMessage.InvitationNotFound]: 'Es wurde keine Einladung gefunden',
  [HttpErrorMessage.MatchHasBetsOrOutcomes]: 'Dieser Match hat bereits einen Tipp oder Resultate',
  [HttpErrorMessage.NoActiveEvent]: 'Kein aktiver Event',
  [HttpErrorMessage.NoEventForThisSeason]: 'Kein aktiver Event in dieser Saison',
  [HttpErrorMessage.DoNotParticipateInCurrentSeason]: 'Dieser Spieler spielt nicht mit in dieser Saison',
  [HttpErrorMessage.GroupDoesNotExist]: 'Diese Gruppe existiert nicht',
  [HttpErrorMessage.MaximumUsesOfJoinLink]: 'Die maximale Anzahl wurde erreicht',
  [HttpErrorMessage.NoJoinRequestsFound]: 'Keine Anfragen vorhanden',
  [HttpErrorMessage.NoSuchQuestion]: 'Diese Frage gibt es nicht für diesen Spieler',
  [HttpErrorMessage.NotLastQuestion]: 'Das ist nicht die letzte Frage',
  [HttpErrorMessage.QuestionIsLinked]: 'Diese Frage ist verlinkt',
  [HttpErrorMessage.QuestionOptionDoesNotExist]: 'Diese Option existiert nicht',
  [HttpErrorMessage.UserNotMember]: 'Dieser Spieler ist nicht Teil der Gruppe',
  [HttpErrorMessage.EventWithProvidedIdDoesNotExist]: 'Der Event mit dieser ID existiert nicht',
  [HttpErrorMessage.MatchDoesNotExist]: 'Dieser Match existiert nicht',
  [HttpErrorMessage.NoPermissionToAcceptUserJoinRequest]: 'Keine Berechtigung um Anfrage zu bestätigen',
  [HttpErrorMessage.NoPermissionToCreateGroupJoinLink]: 'Keine Berechtigung um den Link er erstellen',
  [HttpErrorMessage.NoPermissionsToDeclineUserJoinRequest]: 'Keine Berechtigung um Beitrittsanfrage abzulehnen',
  [HttpErrorMessage.NoPermissionToDeleteJoinRequest]: 'Keine Berechtigung um Beitrittsanfrage zu löschen',
  [HttpErrorMessage.AlreadyExists]: 'Eine Gruppe mit diesem Namen existiert bereits',
  [HttpErrorMessage.MatchAlreadyHasResult]: 'Match hast bereits ein Resultat',
  [HttpErrorMessage.NoMoreQuestions]: 'Keine weiteren Fragen',
  [HttpErrorMessage.RaceDoesNotExist]: 'Das Rennen existiert nicht',
  [HttpErrorMessage.TeamDoesNotExist]: 'Das Team existiert nicht',
  [HttpErrorMessage.QuestionDoesNotExist]: 'Die Frage existiert nicht',
  [HttpErrorMessage.UserDoesNotExist]: 'Spieler existiert nicht',
  [HttpErrorMessage.NoPermissions]: 'Keine Berechtigung',
  [HttpErrorMessage.GroupFull]: 'Die Gruppe ist bereits voll',
  [HttpErrorMessage.UserNameNotFound]: 'Benutzername wurde nicht gefunden',
  [HttpErrorMessage.ProductDoesNotExist]: 'Dieses Produkt existiert nicht',
  [HttpErrorMessage.RoundDoesNotExist]: 'Diese Runde existiert nicht',
  [HttpErrorMessage.GroupNotFound]: 'Gruppe wurde nicht gefunden',
  [HttpErrorMessage.SeasonDoesNotExist]: 'Diese Season existiert nicht',
}

export const DEFAULT_ERRORS = {
  TRY_LATER: 'Leider ist etwas schief gelaufen. Probiere es zu einem späteren Zeitpunkt nochmal.',
  TRY_REFRESH: 'Leider ist etwas schief gelaufen. Versuche, die Seite zu aktualisieren.',
  COMPONENT_IS_BROKEN:
    'Dieser Teil der Seite ist zur Zeit nicht verfügbar. Wir sind dabei, das Problem zu beheben. Danke, für deine Geduld.',
}

export const isBackendError = <T,>(response: T | AxiosError): response is AxiosError => {
  if ((response as AxiosError).isAxiosError) {
    return true
  } else return false
}

export const isHttpError = <T,>(error: T | THttpError): error is THttpError => {
  if ((error as THttpError).message && (error as THttpError).status) {
    return true
  } else return false
}

export const extractBackendErrorMessage = (response: AxiosError): string | undefined => {
  if (typeof response.response?.data === 'string') {
    return response.response?.data
  }
  // @ts-expect-error
  if (response.response?.data?.message) {
    // @ts-expect-error
    return response.response?.data?.message
  }
}

export const getReduxErrorObject = (error: unknown): AnyObject => {
  if (error instanceof Error) {
    return {
      error: error.message,
    }
  }
  return { error }
}

export const checkBackendError = (res: AxiosResponse, defaultError: string) => {
  if (isBackendError(res)) {
    throw HttpError(res as AxiosError, defaultError)
  }
}

export const getBackendError = (res: AxiosResponse): AxiosError | null => {
  if (isBackendError(res)) {
    return res as AxiosError
  }
  return null
}

export const HttpError = (error: AxiosError, defaultError: string) => {
  const errorMessage = extractBackendErrorMessage(error) ? extractBackendErrorMessage(error) : defaultError
  return {
    status: error.response?.status,
    message: errorMessage,
    xTraceId: error.response?.headers['x-trace-id'],
  }
}

const translateHttpError = (message: HttpErrorMessage) => {
  return HttpErrorMessageTranslation[message] || DEFAULT_ERRORS.TRY_REFRESH
}

export const showToastError = <T,>(error: T, options?: ToastOptions) => {
  toasty(
    isHttpError(error) ? (
      <>
        {translateHttpError(error.message)}
        {translateHttpError(error.message) === DEFAULT_ERRORS.TRY_REFRESH && error.xTraceId && (
          <ToastSubtitle>Referenz-ID: {error.xTraceId}</ToastSubtitle>
        )}
      </>
    ) : (
      DEFAULT_ERRORS.TRY_LATER
    ),
    options,
  )
}
