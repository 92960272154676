import { useContext, useEffect, useRef, useState } from 'react'
import { CrosswordContext } from './context'
import styled, { css } from 'styled-components/macro'
import { useMedia } from 'src/utils/media/useMedia'
import { ReactComponent as ArrowBackIcon } from 'src/assets/images/arrow_back_icon.svg'
import { debounce } from 'lodash-es'
import { otherDirection } from './util'

export const SelectedClue = () => {
  const { selectedClue, nextClue, prevClue, handleClueSelected, selectedDirection, clues } =
    useContext(CrosswordContext)
  const { isMobile } = useMedia()
  const [topPosistion, setTopPosition] = useState<number | undefined>()
  const mobileWrapperRef = useRef<HTMLDivElement>(null)
  const { isAndroid } = useMedia()

  const onPrev = () => {
    if (prevClue) {
      handleClueSelected(selectedDirection, prevClue.number)
    } else {
      const other = otherDirection(selectedDirection)
      if (clues) {
        handleClueSelected(other, clues[other][clues[other].length - 1].number)
      }
    }

    setTimeout(() => handleResize(), 1) // wait for the resize of the element to happen
  }

  const onNext = () => {
    if (nextClue) {
      handleClueSelected(selectedDirection, nextClue.number)
    } else {
      const other = otherDirection(selectedDirection)
      if (clues) {
        handleClueSelected(other, clues[other][0].number)
      }
    }

    setTimeout(() => handleResize(), 1) // wait for the resize of the element to happen
  }

  const handleResize = debounce(() => {
    const height = window.visualViewport
      ? window.visualViewport.height + (isAndroid ? 0 : window.pageYOffset)
      : window.innerHeight
    const clueHeight = mobileWrapperRef.current ? mobileWrapperRef.current.offsetHeight : 0
    setTopPosition(height - clueHeight)
  }, 1)

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('resize', handleResize)
      if (!isAndroid) {
        window.visualViewport?.addEventListener('resize', handleResize)
        window.addEventListener('scroll', handleResize)
      }
    }

    return () => {
      window.removeEventListener('resize', handleResize)
      window.visualViewport?.addEventListener('resize', handleResize)
      window.addEventListener('scroll', handleResize)
    }
  }, [isMobile, handleResize, isAndroid])

  if (isMobile) {
    return (
      <SelectedClueWrapper ref={mobileWrapperRef} $topPosistion={topPosistion}>
        <IconWrapper onClick={onPrev} $paddingSide="right">
          <StyledArrowPrevIcon />
        </IconWrapper>
        <InnerWrapper>{`${selectedClue?.number}) ${selectedClue?.clue}`}</InnerWrapper>
        <IconWrapper onClick={onNext} $paddingSide="left">
          <StyledArrowNextIcon />
        </IconWrapper>
      </SelectedClueWrapper>
    )
  }

  return <SelectedClueWrapper>{`${selectedClue?.number}) ${selectedClue?.clue}`}</SelectedClueWrapper>
}

const IconWrapper = styled.div<{ $paddingSide?: 'left' | 'right' }>`
  ${({ $paddingSide }) => $paddingSide && `padding-${$paddingSide}: 35px;`}
  display: flex;
  align-items: center;
`

const SelectedClueWrapper = styled.div<{ $topPosistion?: number }>`
  background: ${({ theme }) => theme.components.Crossword.focusBackground};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.shades.white};
  padding: 0px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  wrap: no-wrap;

  ${({ theme }) => theme.media.isMobile} {
    position: fixed;
    z-index: 1;
    width: 100%;
    padding: 0px 8px;
    display: flex;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    min-height: 40px;
    height: auto;
    ${({ $topPosistion }) =>
      $topPosistion
        ? css`
            top: ${$topPosistion}px;
          `
        : css`
            bottom: 0;
          `}
  }
`

const InnerWrapper = styled.div`
  max-width: 100%;
  flex-grow: 1;
  text-align: center;
`

const StyledArrowPrevIcon = styled(ArrowBackIcon)`
  width: 24px;

  path {
    fill: ${({ theme }) => theme.colors.shades.white};
  }
`

const StyledArrowNextIcon = styled(StyledArrowPrevIcon)`
  path {
    fill: ${({ theme }) => theme.colors.shades.white};
  }

  transform: rotate(-180deg);
`
